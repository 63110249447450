import React from 'react';
import { AppearanceContextProvider } from '@sequor/helius-appearance'
import { NotificationProvider, showNotificationError } from '@sequor/helius-notification';
import { InternationalizationContextProvider, useGetCurrentI18n } from '@sequor/internationalization'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import MainStartup from './Startup/MainStartup';
import SecurityStartup from './Startup/SecurityStartup';
import CoreConfiguration from './core-configuration.json';
import { AppConfiguration } from '@sequor/model';
import { PowerdBySequorLoadingView } from '@sequor/loading-screen';
import ViewVersion from './ViewVersion';
import { TenantContextProvider, useGetTenant } from '@sequor/tenants'
import { FieldContextProvider } from '@sequor/helius-field';
import { SecurityContextProvider, SecurityProtected } from '@sequor/security';
import TenantChoice from './Startup/TenantChoice/TenantChoice';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';
import { monaco } from '@monaco-editor/react';
import WebEnvironmentTNK from './web-environment-tnk'

monaco.config({ paths: { vs: '/monaco-editor/vs' } });

const SecurityLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  return <SecurityContextProvider environmentTNK={WebEnvironmentTNK}>
    {children}
  </SecurityContextProvider>
}

const TenantLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  return <TenantContextProvider fallback={<PowerdBySequorLoadingView />}>
    {children}
  </TenantContextProvider>
}

const InternationalizationLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {

  const { locale } = useGetTenant()

  return <InternationalizationContextProvider
    locale={locale}>
    {children}
  </InternationalizationContextProvider>
}

const FieldLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {

  const { localeParameters } = useGetTenant()

  const currentI18n = useGetCurrentI18n()

  const localeConfig = (localeParameters ?? {})[currentI18n.locale.code]

  return <FieldContextProvider
    dateTimeConfig={localeConfig?.dateTimeConfig}
    locale={currentI18n.locale.locale}
    i18n={currentI18n.entries as any}
    inputConfig={localeConfig?.inputConfig}
    localeParameters={localeParameters}>
    {children}
  </FieldContextProvider>
}

const AppearanceLoading: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  return <AppearanceContextProvider
    sources={{
      type: 'Default'
    }}
    fallback={<PowerdBySequorLoadingView />}>
    {children}
  </AppearanceContextProvider>
}

const App: React.FC = () => {

  AppConfiguration.CoreConfiguration = CoreConfiguration as any
  AppConfiguration.DevelopmentData = {}

  const url = new URL(window.location.href)

  if (url.searchParams.has('viewversion')) {
    return <ViewVersion />
  }

  const handleError = (error: any, errorInfo: any) => {
    console.error(error, errorInfo)
  }

  return <ErrorBoundary onError={handleError} FallbackComponent={ErrorFallback}>
    <SecurityLoading>
      <TenantLoading>
        <InternationalizationLoading>
          <FieldLoading>
            <AppearanceLoading>
              <NotificationProvider>

                <BrowserRouter>
                  <Switch>
                    <Route path="/tenants">
                      <TenantChoice />
                    </Route>
                    <Route exact path="/">
                      <SecurityProtected
                        fallback={<PowerdBySequorLoadingView />}
                        childrenAuthentication={<SecurityStartup />}
                        onGetInitializationParameters={(parameterNameRedefinePassword) => new URL(window.location.href).searchParams.get(parameterNameRedefinePassword)}
                        onError={(erro) => showNotificationError(erro)}>
                        <MainStartup />
                      </SecurityProtected>
                    </Route>
                    <Redirect from='*' to='/' />
                  </Switch>
                </ BrowserRouter>

              </NotificationProvider>
            </AppearanceLoading>
          </FieldLoading>
        </InternationalizationLoading>
      </TenantLoading>
    </SecurityLoading>
  </ErrorBoundary>
}

export default App
