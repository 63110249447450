import { IEnvironmentTNK } from "@sequor/security";
import jwt from 'jsonwebtoken';

class WebEnvironmentTNK implements IEnvironmentTNK {
    private getTokenKey = () => process.env.REACT_APP_TOKEN_KEY

    to(payload: any, expiresIn: number): string {
        expiresIn = Math.round(expiresIn / 1000)
        return jwt.sign(payload, this.getTokenKey(), { algorithm: 'HS256', expiresIn })
    }

    from(token: string) {
        return jwt.verify(token, this.getTokenKey(), { algorithms: ['HS256'], ignoreExpiration: true, ignoreNotBefore: true });
    }
}

export default new WebEnvironmentTNK()